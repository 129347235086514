import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SelectOption } from 'src/app/shared/components/field-select/field-select.interfaces';
import { Video } from '../../admin.interface';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-add-video-into-category',
  templateUrl: './add-video-into-category.component.html',
  styleUrls: ['./add-video-into-category.component.scss'],
})
export class AddVideoIntoCategoryComponent implements OnInit {
  videoForm!: FormGroup;
  loading = false;
  data: Video | undefined;
  videoId: string | undefined;
  categories: SelectOption[] = [];
  selectedCategoryId: string | undefined;
  categoriesLoading = true;

  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private toaster: ToastrService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.videoId = this.route.snapshot.params['id'];
    this.data = this.route.snapshot.data?.message;

    this.loadCategories();

    if (this.videoId && this.data) {
      this.videoForm = this.formBuilder.group({
        title: [this.data.title, [Validators.required]],
        url: [
          this.data.url,
          [Validators.required, Validators.pattern(/^(https?:\/\/)?([\w-]+\.)*[\w-]+\.[a-z]{2,}(\/\S*)?$/)],
        ],
        order: [this.data.order, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        video_category_id: [this.data.video_category_id, [Validators.required]],
      });
      this.selectedCategoryId = this.data.video_category_id;

      this.videoForm.get('video_category_id')?.disable();
    } else {
      this.videoForm = this.formBuilder.group({
        title: ['', [Validators.required]],
        url: [
          '',
          [
            Validators.required,
            Validators.pattern(/^(https?:\/\/)?([\w-]+\.)*[\w-]+\.[a-z]{2,}(\/[^\s?]*)?(\?[^\s]*)?$/),
          ],
        ],
        order: [null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        video_category_id: [null, [Validators.required]],
      });
    }
  }

  private loadCategories() {
    this.categoriesLoading = true;
    this.adminService.getVideoCategories().subscribe({
      next: (categories) => {
        this.categories = categories
          .filter((cat) => cat.active)
          .map((cat) => ({
            id: cat.video_category_id,
            text: cat.name,
          }));
        this.categoriesLoading = false;
      },
      error: (err) => {
        this.categoriesLoading = false;
        this.toaster.error(this.translate.instant('VIDEO_LIBRARY.LOAD_CATEGORIES_ERROR'));
      },
    });
  }

  onSubmit() {
    if (this.videoForm.invalid) {
      this.toaster.warning(this.translate.instant('FORM.SUBMIT_INVALID_FORM'));
      Object.keys(this.videoForm.controls).forEach((key) => {
        const control = this.videoForm.get(key);
        if (control) {
          control.markAsTouched();
          control.markAsDirty();
        }
      });
      return;
    }

    if (this.loading) return;
    this.loading = true;

    const categoryId = this.videoForm.get('video_category_id')?.value;

    const requestData: Partial<Video> = {};

    Object.keys(this.videoForm.controls).forEach((key) => {
      if (this.videoId && !this.videoForm.get(key)?.touched) {
        return;
      }
      if (key !== 'video_category_id') {
        requestData[key as keyof Video] = this.videoForm.get(key)?.value;
      }
    });

    if (!this.videoId) {
      this.adminService.postVideo(categoryId, requestData).subscribe({
        next: () => {
          this.loading = false;
          this.videoForm.reset();
          this.toaster.success(this.translate.instant('VIDEO_LIBRARY.ADD_SUCCESS'));
          this.router.navigate(['/admin/video-library']);
        },
        error: (err) => {
          this.loading = false;
          this.toaster.error(err.error.message || err.error);
        },
      });
    } else {
      this.adminService.patchVideo(categoryId, this.videoId, requestData).subscribe({
        next: () => {
          this.loading = false;
          this.videoForm.reset();
          this.toaster.success(this.translate.instant('VIDEO_LIBRARY.UPDATE_SUCCESS'));
          this.router.navigate(['/admin/video-library']);
        },
        error: (err) => {
          this.loading = false;
          this.toaster.error(err.error.message || err.error);
        },
      });
    }
  }
}
