<app-header></app-header>
<div class="e-video-category-page">
  <section id="main-content">
    <div class="e-video-category-page__header">
      <header class="c-page-header">
        <h2 class="c-page-header__title" translate>VIDEO_CATEGORY.TITLE</h2>
        <app-button icon="add" [routerLink]="['/admin/video-category/add-video-category']">{{
          'VIDEO_CATEGORY.NEW_CATEGORY' | translate
        }}</app-button>
      </header>
    </div>

    <div class="e-video-category-page__content-box">
      <div class="e-video-category-page__content-box__content">
        <div class="mb-4 e-video-category-page__content-header">
          <div class="d-flex">
            <app-chip-list
              class="u-animation-fade-in e-video-category-page__chip-list"
              (chipSelected)="setFilter($event)">
              <app-choice-chip
                value="active"
                [selected]="true"
                [label]="'VIDEO_CATEGORY.ACTIVE' | translate"
                [mobileLabel]="'VIDEO_CATEGORY.ACTIVE' | translate"
                [counter]="activeCategoryList.length">
              </app-choice-chip>
              <app-choice-chip
                value="inactive"
                [label]="'VIDEO_CATEGORY.ARCHIVED' | translate"
                [mobileLabel]="'VIDEO_CATEGORY.ARCHIVED' | translate"
                [counter]="inactiveCategoryList.length">
              </app-choice-chip>
            </app-chip-list>
          </div>
        </div>
        <div class="card mb-4">
          <div class="table-responsive e-video-category-page__table">
            <table class="table table-hover mb-0">
              <thead>
                <tr>
                  <th translate>VIDEO_CATEGORY.ORDER</th>
                  <th translate>VIDEO_CATEGORY.NAME</th>
                  <th translate>VIDEO_CATEGORY.VIDEOS_COUNT</th>
                  <th class="text-center" translate>VIDEO_CATEGORY.ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="isLoading">
                  <td colspan="4" class="text-center pb-3 pt-3">
                    <div class="spinner-border" role="status">
                      <span class="sr-only" translate>Loading</span>
                    </div>
                  </td>
                </tr>
                <ng-container *ngIf="!isLoading && displayCategoryList.length === 0">
                  <tr>
                    <td *ngIf="!failedToLoad" colspan="4" class="text-center" translate>
                      VIDEO_CATEGORY.NO_CATEGORIES
                    </td>
                    <td *ngIf="failedToLoad" colspan="4" class="text-center">
                      <ion-icon name="alert-circle-outline"></ion-icon>
                      {{ 'VIDEO_CATEGORY.ERROR_MESSAGE' | translate }}
                    </td>
                  </tr>
                </ng-container>
                <tr
                  class="e-video-category-page__table-item"
                  (click)="editCategory(category.video_category_id)"
                  *ngFor="let category of displayCategoryList">
                  <td>{{ category.order }}</td>
                  <td>{{ category.name }}</td>
                  <td>{{ category.videos_count }}</td>
                  <td>
                    <div class="d-flex w-100 justify-content-center">
                      <app-button
                        class="c-class-card__action-button mr-2"
                        icon="pencil"
                        [iconOnly]="true"
                        variant="untoggled"
                        [routerLink]="['/admin/video-category/edit-video-category/' + category.video_category_id]">
                      </app-button>
                      <app-button
                        class="c-class-card__action-button"
                        [icon]="viewMode === 'active' ? 'archive-outline' : 'power'"
                        [iconOnly]="true"
                        variant="untoggled"
                        [loading]="!!togglingMap[category.video_category_id]"
                        (click)="toggleActive($event, category)">
                      </app-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
