import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import Swal from 'sweetalert2';
import { VideoCategory } from '../admin.interface';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-video-category',
  templateUrl: './video-category.component.html',
  styleUrls: ['./video-category.component.scss'],
})
export class VideoCategoryComponent implements OnInit {
  searchText: string = '';
  viewMode: 'active' | 'archived' = 'active';

  activeCategoryList: VideoCategory[] = [];
  inactiveCategoryList: VideoCategory[] = [];
  displayCategoryList: VideoCategory[] = [];

  isLoading = true;
  failedToLoad = false;

  togglingMap: Record<string, boolean> = {};

  constructor(
    private adminService: AdminService,
    private toaster: ToastrService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadCategories();
  }

  loadCategories() {
    this.isLoading = true;
    this.displayCategoryList = [];
    this.adminService
      .getVideoCategories()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.activeCategoryList = data.filter((cat) => cat.active);
          this.inactiveCategoryList = data.filter((cat) => !cat.active);
          this.updateDisplayList();
        },
        error: () => {
          this.failedToLoad = true;
        },
      });
  }

  toggleActive(ev: any, category: VideoCategory) {
    if (this.togglingMap[category.video_category_id]) return;

    const title = category.active
      ? this.translate.instant('VIDEO_CATEGORY.ARCHIVE_TITLE')
      : this.translate.instant('VIDEO_CATEGORY.UNARCHIVE_TITLE');
    const text = category.active
      ? this.translate.instant('VIDEO_CATEGORY.ARCHIVE_TEXT')
      : this.translate.instant('VIDEO_CATEGORY.UNARCHIVE_TEXT');

    ev.stopPropagation();
    Swal.fire({
      title,
      text,
      icon: 'info',
      customClass: 'c-swal-modal',
      showCancelButton: true,
      confirmButtonColor: '#2F69B0',
      cancelButtonColor: '#F1F8FF',
      reverseButtons: true,
      confirmButtonText: category.active
        ? this.translate.instant('VIDEO_CATEGORY.SET_ARCHIVED_BTN')
        : this.translate.instant('VIDEO_CATEGORY.SET_ACTIVE_BTN'),
      cancelButtonText: this.translate.instant('VIDEO_CATEGORY.CANCEL_BTN'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.togglingMap[category.video_category_id] = true;
        this.adminService.patchVideoCategory(category.video_category_id, { active: !category.active }).subscribe({
          next: () => {
            this.togglingMap[category.video_category_id] = false;
            const message = category.active
              ? this.translate.instant('VIDEO_CATEGORY.SET_ARCHIVED_SUCCESS')
              : this.translate.instant('VIDEO_CATEGORY.SET_ACTIVE_SUCCESS');
            this.toaster.success(message);
            this.loadCategories();
          },
          error: () => {
            this.togglingMap[category.video_category_id] = false;
            const message = category.active
              ? this.translate.instant('VIDEO_CATEGORY.SET_ARCHIVED_ERROR')
              : this.translate.instant('VIDEO_CATEGORY.SET_ACTIVE_ERROR');
            this.toaster.error(message);
          },
        });
      }
    });
  }

  editCategory(categoryId: string) {
    this.router.navigate(['admin/video-category/edit-video-category/' + categoryId]);
  }

  setFilter(event: string) {
    this.viewMode = event as 'active' | 'archived';
    this.updateDisplayList();
  }

  updateDisplayList() {
    this.displayCategoryList = this.viewMode === 'active' ? this.activeCategoryList : this.inactiveCategoryList;
  }
}
