import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from 'src/app/shared/services/api.services';
import Swal from 'sweetalert2';
import { BeltHistoryResponse, StudentData } from '../state/student.interface';
import { StudentService } from '../state/student.service';

@Component({
  selector: 'app-view-student',
  templateUrl: './view-student.component.html',
  styleUrls: ['./view-student.component.scss'],
})
export class ViewStudentComponent implements OnInit {
  student: StudentData | null = null;

  activeTab: string = 'details';

  beltHistory: BeltHistoryResponse[] = [];
  isLoadingBeltHistory = false;

  @ViewChild('tabsContainer') tabsContainer!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private Apiservice: ApiService,
    private studentService: StudentService,
    public sanitizer: DomSanitizer,
    private translate: TranslateService,
    public toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.student = this.route.snapshot.data?.message;
    console.log(this.student);
  }

  confirmPauseMembership(studentId: string) {
    Swal.fire({
      title: this.translate.instant('Pause Membership'),
      text: this.translate.instant('Are you sure you want to pause the membership for this student?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.pauseMembership(studentId);
      }
    });
  }

  confirmResumeMembership(studentId: string) {
    Swal.fire({
      title: this.translate.instant('Resume Membership'),
      text: this.translate.instant('Are you sure you want to resume the membership for this student?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.resumeMembership(studentId);
      }
    });
  }

  pauseMembership(studentId: string) {
    if (studentId) {
      this.Apiservice.postApi(endpoints.pauseMembership.path, { student_id: studentId }).subscribe(
        (data: any) => {
          console.log(data);

          this.toaster.success(this.translate.instant(data.message));
        },
        (err: any) => {
          this.toaster.error(this.translate.instant(err.error.message));
        }
      );
    }
  }

  resumeMembership(studentId: string) {
    console.log(studentId);

    if (studentId) {
      this.Apiservice.postApi(endpoints.resumeMembership.path, { student_id: studentId }).subscribe(
        (data: any) => {
          console.log(data);

          this.toaster.success(this.translate.instant(data.message));
        },
        (err: any) => {
          this.toaster.error(this.translate.instant(err.error.message));
        }
      );
    }
  }

  handleTabChange(event: any) {
    this.activeTab = event;

    if (event === 'belt-history' && this.student) {
      this.loadBeltHistory();
    }
    // Ensure the selected tab is visible by scrolling to it
    setTimeout(() => {
      const selectedTab = this.tabsContainer.nativeElement.querySelector(`app-choice-chip[value="${event}"]`);
      if (selectedTab) {
        selectedTab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    });
  }

  loadBeltHistory() {
    if (!this.student) return;

    this.isLoadingBeltHistory = true;
    this.studentService.getBeltHistory(this.student.user_id).subscribe({
      next: (history) => {
        this.beltHistory = history;
        this.isLoadingBeltHistory = false;
      },
      error: (error) => {
        this.toaster.error(this.translate.instant('Failed to load belt history'));
        this.isLoadingBeltHistory = false;
      },
    });
  }
}
