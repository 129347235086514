<nav class="navbar w-100">
  <div class="navbar-left">
    <div class="navbar-btn">
      <app-button icon="menu" [iconOnly]="true" (click)="mToggoleMenu()"></app-button>
    </div>
  </div>
  <div class="app-menu">
    <span class="header-title" *ngIf="isMobile" translate>{{ pageTitle$ | async }}</span>
    <button class="btn auth-button ml-2" *ngIf="userBankAccount === false" (click)="addbank()">
      <a class="text-white" target="_blank" translate>Add Account</a>
    </button>
  </div>
  <div class="navbar-right">
    <app-button
      icon="close"
      variant="secondary"
      [iconOnly]="true"
      *ngIf="isMobile && (displayGoBack$ | async)"
      (click)="goBack()"></app-button>
    <div id="navbar-menu" *ngIf="!isMobile">
      <ul class="nav navbar-nav">
        <!--  <li class="dropdown">
          <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown">
            <i class="fas fa-envelope"></i>
          </a>
          <ul class="dropdown-menu dropdown-list dropdown-menu-right pb-0">
            <li *ngFor="let msg of msglist">
              <div class="image-section p-2">
                <div class="message-name-date border p-2">
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                      <h6 class="message-text-col">{{ msg?.user?.first_name }} {{ msg?.user?.last_name }}</h6>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                      <p class="float-right">{{ msg.created_at | date: 'dd-MM-YYY' }}</p>
                    </div>
                  </div>
                  <p>{{ msg.message }}</p>
                </div>
              </div>
            </li>
            <button class="auth-button w-100" [routerLink]="['/admin/messages']" translate>View All</button>
          </ul>
        </li> -->
        <!--     <li class="dropdown">
          <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown">
            <i class="far fa-bell"></i>
          </a>
          <ul class="dropdown-menu dropdown-list dropdown-menu-right pb-0">
            <li *ngFor="let notify of readnotify">
              <div class="image-section p-2">
                <div class="message-name-date border p-2">
                  <p>
                    <strong>{{ notify.title }}</strong> {{ notify.notification_message }}
                  </p>
                </div>
              </div>
            </li>
            <button class="auth-button w-100" [routerLink]="['/admin/notification']" translate>View All</button>
          </ul>
        </li> -->
        <div class="dropdown d-inline">
          <a class="icon-menu" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
            <i class="fas fa-globe"></i>
          </a>
          <div class="dropdown-menu language-dropdown-menu mt-3" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item language-dropdown-items" (click)="useLanguage('en')">English</a>
            <a class="dropdown-item language-dropdown-items" (click)="useLanguage('es')">Spanish</a>
            <a class="dropdown-item language-dropdown-items" (click)="useLanguage('pt-br')">Portuguese</a>
          </div>
        </div>
        <li class="hidden-xs">
          <a
            *ngIf="!isFullScreen"
            href="javascript:void(0);"
            (click)="openfullScreen()"
            id="btnFullscreen"
            class="icon-menu"
            ><i class="fa fa-arrows-alt"></i
          ></a>
          <a
            *ngIf="isFullScreen"
            href="javascript:void(0);"
            (click)="closeFullScreen()"
            id="btnFullscreen"
            class="icon-menu"
            ><i class="fa fa-arrows-alt"></i
          ></a>
        </li>
        <li>
          <a (click)="logOut()" href="javascript:void(0)" class="icon-menu"><i class="fa fa-power-off"></i></a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<app-leftmenu (onLogout)="logOut()" (onLanguageChanged)="useLanguage($event)"></app-leftmenu>
