import * as moment from 'moment';

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { DaterangepickerDirective, LocaleConfig } from 'ngx-daterangepicker-material';
import { MonthArray, WeekArray } from './date-picker.helper';

@Component({
  selector: 'app-datepicker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective!: DaterangepickerDirective;

  @Input() isRequired: boolean = false;

  @Input() labelClass: string | undefined;

  @Input() inputClass: string | undefined;

  @Input() calendarLabel: string | undefined;

  @Input() calendarPlaceholder: string | undefined;

  @Input() calendarLocale: LocaleConfig | undefined;

  @Input() startDate: Moment | any;

  @Input() endDate: Moment | any;

  @Input() variant: 'range' | 'date' = 'range';

  @Input() minDate: Moment | any;

  @Input() maxDate: Moment | any;

  @Input() clearDate: boolean | undefined;

  @Input() showDropdowns: boolean = false;

  @Input() helperText: string | undefined = undefined;

  @Input() showLabel: boolean = true;

  @Output() OnDate = new EventEmitter();

  @Output() onClear = new EventEmitter();

  locale: LocaleConfig | undefined;

  defaultLocale: LocaleConfig | undefined;

  ranges: any;

  moment = moment;

  selectedRange: any;

  inputValue: string = '';

  touched: boolean = false;

  invalidFormat: boolean = false;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.getDefaultLocale();
    this.locale = { ...this.defaultLocale, ...this.calendarLocale };

    this.translate.onLangChange.subscribe(() => {
      this.getDefaultLocale();
      this.locale = { ...this.defaultLocale, ...this.calendarLocale };
    });

    this.ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.startDate?.previousValue !== changes?.startDate?.currentValue && this.startDate) {
      this.selectedRange = {
        startDate: moment(this.startDate).toDate(),
        endDate: moment(this.endDate).toDate(),
      };
    }

    if (changes.startDate?.currentValue === null && changes.endDate?.currentValue === null) {
      this.clear();
    }
  }

  getDefaultLocale() {
    this.defaultLocale = {
      displayFormat:
        this.variant === 'range'
          ? this.translate.instant('CALENDAR.RANGE_FIELD_DISPLAY_FORMAT')
          : this.translate.instant('CALENDAR.DATE_FIELD_DISPLAY_FORMAT'),
      daysOfWeek: this.populateOptions('DAYS', WeekArray),
      monthNames: this.populateOptions('MONTHS', MonthArray),
      firstDay: 0,
    };
  }

  isCustomDate(date: any) {
    return date.isSame(moment(), 'day') ? 'today' : false;
  }

  selectedDate(event: any) {
    this.selectedRange = event;
    this.invalidFormat = false;
    this.OnDate.emit(event);
  }

  clear() {
    this.selectedRange = '';
    this.startDate = null;
    this.endDate = null;
    this.onClear.emit();
  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  onInput(value: string) {
    this.inputValue = value;
    if (!this.inputValue) {
      this.selectedRange = '';
      this.selectedDate(this.selectedRange);
    }
  }

  onBlur() {
    this.touched = true;

    const date = moment(this.inputValue, this.translate.instant('CALENDAR.DATE_FIELD_INPUT_FORMAT'));

    if (date.isValid()) {
      this.selectedRange = {
        startDate: date,
        endDate: date,
      };

      this.selectedDate(this.selectedRange);
    } else {
      this.invalidFormat = true;
    }
  }

  private populateOptions(prefix: string, values: string[]): string[] {
    return values.map((el: string) => {
      return this.translate.instant('CALENDAR.' + (prefix ? `${prefix}.` : '') + el.toUpperCase());
    });
  }
}
