/**
 * Environment variables used for admin.tatamiapp.dev
 * These are different from environment.ts, as they require a production build
 */

export const environment = {
  production: true,
  configUrl: '',
  BACKEND_BASE_URL: 'https://api.tatamiapp.dev/api/',
  kioskUrl: 'https://kiosk.tatamiapp.dev/',

  rollbar: {
    accessToken: 'c6a1a6d381c74dad80741fcd49ef595b',
    environment: 'development',
    enabled: true,
  },

  stripePublishableKey: 'pk_test_51KS7KYFac4ePSkcvLBW4hkF2s3aCM5QxZ283c7A4Mv0AcuKayOeDamlIT7wgfcQbNe1mYO3tRUTyARCJQdP4Icq600vKKSD2TG'
};
