<app-header></app-header>

<div id="main-content" ngxUiLoaderBlurred>
  <div class="container-fluid">
    <div class="dashboard__header">
      <header class="c-page-header">
        <h2 class="c-page-header__title" translate>DASHBOARD_PAGE.TITLE</h2>
      </header>
    </div>

    <!-- Onboarding View -->
    <div *ngIf="hasIncompleteSteps" class="row dashboard__onboarding p-4">
      <ng-container *ngIf="loadingState; else loadedContent">
        <div class="col-md-4">
          <div class="todo skeleton">
            <div
              class="todo__item skeleton-item d-flex align-items-center p-3 mb-2"
              *ngFor="let i of [1, 2, 3, 4, 5]">
              <span class="todo__number skeleton-circle me-3"></span>
              <span class="todo__title skeleton-text"></span>
            </div>
          </div>
        </div>
        <div class="col-md-8 mt-4 mt-md-0">
          <div class="todo__content">
            <div>
              <h3 class="skeleton-text skeleton-title mb-3"></h3>
              <p class="skeleton-text mb-4"></p>
              <p class="skeleton-text mb-4"></p>
              <div class="skeleton-button"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #loadedContent>
        <div class="col-md-4">
          <div class="todo">
            <ng-container *ngFor="let todo of todos">
              <div
                class="todo__item d-flex align-items-center p-3 mb-2"
                [class.todo__item--active]="selectedTodo?.id === todo.id"
                [class.todo__item--completed]="todo.completed"
                (click)="selectTodo(todo)">
                <span class="todo__number me-3">
                  <ng-container *ngIf="!todo.completed; else checkmark">{{ todo.id }}</ng-container>
                  <ng-template #checkmark><ion-icon name="checkmark-outline"></ion-icon></ng-template>
                </span>
                <span class="todo__title">{{ todo.title }}</span>
              </div>

              <!-- Mobile content that shows below each selected item -->
              <div class="todo__content-wrapper d-md-none">
                <div *ngIf="selectedTodo?.id === todo.id" class="todo__content">
                  <div>
                    <h3 class="todo__content-title mb-3">{{ todo.title }}</h3>
                    <p class="todo__content-description mb-4">{{ todo.description }}</p>

                    <app-button
                      [fullWidth]="false"
                      size="sm"
                      variant="primary"
                      (click)="handleTodoCta(todo)"
                      [loading]="loading">
                      <span *ngIf="!loading" class="pl-1 pr-1">{{ todo.cta }}</span>
                    </app-button>

                    <div *ngIf="todo.id < todos.length && hasNextIncompleteStep(todo)" class="mt-1">
                      <app-button
                        [fullWidth]="false"
                        size="sm"
                        variant="clear"
                        (click)="selectTodo(getNextIncompleteTodo(todo))"
                        ><span class="pl-1 pr-1" translate>ONBOARDING_STEPS.NEXT_STEP</span></app-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-md-8 mt-4 mt-md-0 d-none d-md-block">
          <div *ngIf="selectedTodo" class="todo__content">
            <div>
              <h3 class="todo__content-title mb-3">{{ selectedTodo.title }}</h3>
              <p class="todo__content-description mb-4">{{ selectedTodo.description }}</p>

              <app-button
                [fullWidth]="false"
                size="sm"
                variant="primary"
                (click)="handleTodoCta(selectedTodo)"
                [loading]="loading">
                <span *ngIf="!loading" class="pl-1 pr-1">{{ selectedTodo.cta }}</span>
              </app-button>

              <div *ngIf="selectedTodo.id < todos.length && hasNextIncompleteStep(selectedTodo)" class="mt-1">
                <app-button
                  [fullWidth]="false"
                  size="sm"
                  variant="clear"
                  (click)="selectTodo(getNextIncompleteTodo(selectedTodo))"
                  ><span class="pl-1 pr-1" translate>ONBOARDING_STEPS.NEXT_STEP</span></app-button
                >
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <!-- Existing Dashboard View -->
    <div *ngIf="!hasIncompleteSteps">
      <!-- Statistics Cards -->
      <div class="row clearfix">
        <div class="col-md-6 dashboard__card-section mb-3 mb-md-0">
          <div class="dashboard__card">
            <div class="dashboard__card-header">
              <div class="dashboard__card-header-title" translate>DASHBOARD_PAGE.TOTAL_STUDENTS</div>
            </div>
            <div class="dashboard__card-body">
              <ng-container *ngIf="!loadingStatistics; else loadingTpl">
                <h3 class="dashboard__stat-number">{{ statistics?.students_count || 0 }}</h3>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="col-md-6 dashboard__card-section">
          <div class="dashboard__card">
            <div class="dashboard__card-header">
              <div class="dashboard__card-header-title" translate>DASHBOARD_PAGE.CHECKINS_THIS_MONTH</div>
            </div>
            <div class="dashboard__card-body">
              <ng-container *ngIf="!loadingStatistics; else loadingTpl">
                <h3 class="dashboard__stat-number">{{ statistics?.checkins_this_month_count || 0 }}</h3>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <!-- Latest Students Section -->
    </div>

    <!-- Add after the statistics cards section -->
    <div class="row clearfix" *ngIf="!hasIncompleteSteps">
      <div class="col-12 dashboard__card-section mt-4">
        <div class="dashboard__card">
          <div class="dashboard__card-header">
            <div class="dashboard__card-header-title-bold" translate>DASHBOARD_PAGE.PROMOTABLE_STUDENTS.TITLE</div>
          </div>
          <div class="dashboard__card-body">
            <div class="dashboard__table-wrapper">
              <div class="table-responsive">
                <table class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th translate>DASHBOARD_PAGE.PROMOTABLE_STUDENTS.NAME</th>
                      <th translate>DASHBOARD_PAGE.PROMOTABLE_STUDENTS.CURRENT_BELT</th>
                      <th translate>DASHBOARD_PAGE.PROMOTABLE_STUDENTS.PROGRESS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="loadingPromotableStudents">
                      <td colspan="3" class="text-center pb-3 pt-3">
                        <div class="spinner-border" role="status">
                          <span class="sr-only" translate>DASHBOARD_PAGE.PROMOTABLE_STUDENTS.LOADING</span>
                        </div>
                      </td>
                    </tr>

                    <ng-container *ngIf="promotableStudentsError">
                      <tr>
                        <td colspan="3" class="text-center pb-3 pt-3">
                          <ion-icon name="alert-circle-outline"></ion-icon>
                          {{ promotableStudentsError }}
                        </td>
                      </tr>
                    </ng-container>

                    <ng-container *ngIf="!promotableStudentsError && (promotableStudents$ | async) as students">
                      <tr
                        *ngFor="
                          let student of students
                            | paginate
                              : {
                                  itemsPerPage: (pageData$ | async)?.perPage,
                                  currentPage: (pageData$ | async)?.currentPage,
                                  totalItems: (pageData$ | async)?.total,
                                  id: 'promotable'
                                }
                        "
                        [routerLink]="['/admin/student/view-student/' + student.user_id]">
                        <td class="dashboard__table-cell-title">{{ student.name }}</td>
                        <td class="dashboard__table-cell">{{ student.belt.name }}</td>
                        <td class="dashboard__table-cell--support">
                          {{ student.checkins_in_current_belt_count }}/{{ student.belt.minimum_attendance }}
                        </td>
                      </tr>

                      <tr *ngIf="!loadingPromotableStudents && students.length === 0">
                        <td colspan="3" class="text-center" translate>
                          DASHBOARD_PAGE.PROMOTABLE_STUDENTS.NO_STUDENTS
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>

            <pagination-controls
              *ngIf="(promotableStudents$ | async)?.length"
              id="promotable"
              class="text-end mt-4"
              (pageChange)="onPromotableStudentsPageChange($event)"
              [previousLabel]="'Previous' | translate"
              [nextLabel]="'Next' | translate">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>

    <!-- Add before the promotable students table -->
    <div class="row clearfix" *ngIf="!hasIncompleteSteps">
      <div class="col-12 dashboard__card-section mt-4">
        <div class="dashboard__card">
          <div class="dashboard__card-header">
            <div class="dashboard__card-header-title-bold" translate>DASHBOARD_PAGE.RECENT_STUDENTS.TITLE</div>
            <p class="dashboard__card-header-subtitle" translate>DASHBOARD_PAGE.RECENT_STUDENTS.SUBTITLE</p>
          </div>
          <div class="dashboard__card-body">
            <div class="dashboard__table-wrapper dashboard__table-wrapper--no-border-top">
              <div class="table-responsive">
                <table class="table table-hover mb-0">
                  <tbody>
                    <tr *ngIf="loadingRecentStudents">
                      <td colspan="3" class="text-center pb-3 pt-3">
                        <div class="spinner-border" role="status">
                          <span class="sr-only" translate>DASHBOARD_PAGE.RECENT_STUDENTS.LOADING</span>
                        </div>
                      </td>
                    </tr>

                    <ng-container *ngIf="recentStudentsError">
                      <tr>
                        <td colspan="3" class="text-center pb-3 pt-3">
                          <ion-icon name="alert-circle-outline"></ion-icon>
                          {{ recentStudentsError }}
                        </td>
                      </tr>
                    </ng-container>

                    <ng-container *ngIf="!recentStudentsError && (recentStudents$ | async) as students">
                      <tr
                        *ngFor="
                          let student of recentStudents
                            | paginate
                              : {
                                  itemsPerPage: (recentPageData$ | async)?.perPage,
                                  totalItems: (recentPageData$ | async)?.total,
                                  currentPage: (recentPageData$ | async)?.currentPage,
                                  id: 'recent'
                                }
                        "
                        class="dashboard__table-row"
                        [routerLink]="['/admin/student/view-student/' + student.user_id]">
                        <td class="dashboard__table-cell--borderless">
                          <div class="dashboard__table-cell-wrapper">
                            <div class="dashboard__table-cell-profile-picture">
                              <img
                                [src]="student.photo_url || 'assets/images/empty-profile.jpg'"
                                alt="Student Profile Picture" />
                            </div>
                            <div class="dashboard__table-cell-wrapper-inner">
                              <div class="dashboard__table-cell-title">{{ student.name }}</div>
                              <div class="dashboard__table-cell-subtitle">
                                {{ student.created_at | date: 'yyyy-MM-dd' }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="dashboard__table-cell--align-right">
                          <span *ngFor="let program of student.programs_as_student; let last = last">
                            {{ program.name }}<span *ngIf="!last">, </span>
                          </span>
                        </td>
                      </tr>

                      <tr *ngIf="!loadingRecentStudents && students.length === 0">
                        <td colspan="3" class="text-center" translate>
                          DASHBOARD_PAGE.RECENT_STUDENTS.NO_STUDENTS
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>

            <pagination-controls
              *ngIf="recentStudents.length"
              id="recent"
              class="text-end mt-4"
              (pageChange)="onRecentStudentsPageChange($event)"
              [previousLabel]="'Previous' | translate"
              [nextLabel]="'Next' | translate">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTpl>
  <div class="skeleton-text skeleton-title"></div>
</ng-template>
