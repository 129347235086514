<app-header></app-header>
<div class="e-video-library-page">
  <section id="main-content">
    <div class="e-video-library-page__header">
      <header class="c-page-header">
        <h2 class="c-page-header__title" translate>Video Library</h2>
        <app-button icon="add" (click)="openAddVideoModal()">{{ 'Add Video' | translate }}</app-button>
      </header>
    </div>

    <div class="e-video-library-page__content-box">
      <div class="e-video-library-page__content-box__content">
        <!-- Loading state for categories -->
        <ng-container *ngIf="!loadingCategories; else loadingCategoriesTemplate">
          <!-- Video Categories -->
          <div class="video-categories" *ngFor="let category of videoCategories">
            <div class="category-header" (click)="toggleCategory(category.video_category_id)">
              <h4 class="category-title">{{ category.name }}</h4>
              <i
                class="fas"
                [class.fa-chevron-down]="!isCategoryCollapsed(category.video_category_id)"
                [class.fa-chevron-right]="isCategoryCollapsed(category.video_category_id)"></i>
            </div>

            <div class="videos-row" [class.collapsed]="isCategoryCollapsed(category.video_category_id)">
              <div class="videos-container" [id]="'container-' + category.video_category_id">
                <ng-container *ngIf="!loadingStates[category.video_category_id]; else loading">
                  <ng-container *ngIf="categoryVideos[category.video_category_id]?.length > 0; else noVideos">
                    <div *ngFor="let video of categoryVideos[category.video_category_id]">
                      <div class="video-card" [class.inactive]="!video.active">
                        <!-- Video Preview Image/Thumbnail -->
                        <div class="video-thumbnail" (click)="openVideo(video)">
                          <img [src]="getVideoThumbnail(video.url)" alt="Video thumbnail" />
                          <div class="play-overlay">
                            <i class="fas fa-play"></i>
                          </div>
                        </div>

                        <!-- Video Info -->
                        <div class="video-info">
                          <!-- Action Buttons -->
                          <div class="video-actions">
                            <div class="action-buttons" [class.active]="activeSettingsMenu === video.video_id">
                              <app-button
                                class="c-class-card__action-button"
                                icon="pencil"
                                [iconOnly]="true"
                                variant="primary"
                                (click)="editVideo(category.video_category_id, video.video_id)">
                              </app-button>
                              <app-button
                                class="c-class-card__action-button"
                                icon="trash"
                                [iconOnly]="true"
                                variant="primary"
                                (click)="deleteVideo(video)">
                              </app-button>
                              <app-button
                                class="c-class-card__action-button"
                                [loading]="toggleLoadingStates[video.video_id]"
                                [icon]="video.active ? 'eye-off' : 'eye'"
                                [iconOnly]="true"
                                variant="primary"
                                [disabled]="toggleLoadingStates[video.video_id]"
                                (click)="toggleVideoStatus(video)">
                              </app-button>
                            </div>
                            <app-button
                              class="settings-button"
                              icon="cog"
                              [iconOnly]="true"
                              variant="primary"
                              (click)="toggleSettingsMenu(video.video_id)">
                            </app-button>
                          </div>
                        </div>
                      </div>
                      <div class="video-info__title">
                        <h4>{{ video.title }}</h4>
                        <p>{{ video.description }}</p>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-template #loading>
                  <div class="loading-container">
                    <div class="loading-cards">
                      <div class="loading-card" *ngFor="let i of [1, 2, 3]">
                        <div class="loading-thumbnail"></div>
                        <div class="loading-info">
                          <div class="loading-title"></div>
                          <div class="loading-description"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <ng-template #noVideos>
                  <div class="empty-state-container">
                    <div class="empty-state">
                      <i class="fas fa-film"></i>
                      <p>{{ 'VIDEO_LIBRARY.NO_VIDEOS' | translate }}</p>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #loadingCategoriesTemplate>
          <div class="loading-categories">
            <div class="loading-category" *ngFor="let i of [1, 2, 3]">
              <div class="loading-category-title"></div>
              <div class="loading-container">
                <div class="loading-cards">
                  <div class="loading-card" *ngFor="let j of [1, 2, 3]">
                    <div class="loading-thumbnail"></div>
                    <div class="loading-info">
                      <div class="loading-title"></div>
                      <div class="loading-description"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </section>
</div>
