import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-video-iframe-dialog',
  templateUrl: './video-iframe-dialog.component.html',
  styleUrls: ['./video-iframe-dialog.component.css'],
})
export class VideoIframeDialogComponent implements OnInit {
  @Input() link: any;
  @Input() index: any;
  @Input() section: any;
  schoolname: any;
  userid: any;

  constructor(
    public modalService: NgbModal,
    private AuthService: AuthService
  ) {}

  ngOnInit(): void {
    this.schoolname = this.AuthService.schoolname;
    this.userid = this.AuthService.userid;
  }

  Close() {
    this.modalService.dismissAll();
  }
}
