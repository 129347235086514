import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { StudentCheckins, StudentCheckinsDashboard } from 'src/app/modules/admin/student/state/student.interface';
import { StudentService } from 'src/app/modules/admin/student/state/student.service';

@Component({
  selector: 'app-admin-attendance',
  templateUrl: './admin-attendance.component.html',
  styleUrls: ['./admin-attendance.component.scss'],
})
export class AdminAttendanceComponent implements OnInit {
  listattendance: StudentCheckins['data'] = [];
  routevalue: any;
  router_id: any;
  urlSegments: any;

  isLoading = false;
  errorMessage: string | null = null;
  pageSize = 10;
  currentPage = 1;
  totalItems = 0;

  dashboardData: StudentCheckinsDashboard | null = null;
  isDashboardLoading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studentService: StudentService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const urlTree = this.router.parseUrl(val.url);
        this.urlSegments = urlTree.root.children['primary'].segments.map((segment) => segment.path);
      }
    });
  }

  ngOnInit(): void {
    this.routevalue = this.route.params.subscribe((params) => {
      this.router_id = params['id'];
      this.loadDashboardData();
      this.loadCheckins();
    });
  }

  loadDashboardData() {
    this.isDashboardLoading = true;
    this.studentService
      .getStudentCheckinsDashboard(this.router_id)
      .pipe(finalize(() => (this.isDashboardLoading = false)))
      .subscribe({
        next: (response) => {
          this.dashboardData = response;
        },
        error: (error) => {
          this.toastr.error(this.translate.instant('STUDENT_PAGE.ERROR_LOADING_ATTENDANCE_DASHBOARD'));
        },
      });
  }

  loadCheckins() {
    this.isLoading = true;
    this.listattendance = [];
    this.studentService
      .getStudentCheckins(this.router_id, { page: this.currentPage })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (response: StudentCheckins) => {
          this.listattendance = response.data;
          this.totalItems = response.meta.total;
          this.pageSize = response.meta.per_page;

          this.errorMessage = null;
        },
        error: (error) => {
          this.errorMessage = 'Failed to load check-ins. Please refresh the page.';
          console.error('Error loading check-ins:', error);
        },
      });
  }

  pageChange(event: number) {
    this.currentPage = event;
    this.loadCheckins();
  }
}
