import { ElementRef, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(
    private toaster: ToastrService,
    private translate: TranslateService
  ) {}

  handleImageUpload(
    file: File,
    options: {
      form: FormGroup;
      formControlName: string;
      previewElement?: ElementRef;
      previewElementId?: string;
      minWidth?: number;
      minHeight?: number;
    }
  ): void {
    if (!file) return;

    // Check file type
    if (!file.type.match(/^image\/(png|jpg|jpeg)$/)) {
      this.toaster.error(this.translate.instant('ERROR_MESSAGES.ONLY_PNG_JPG_JPEG_IMAGES_ALLOWED'));
      return;
    }

    // Check file size (10MB = 10 * 1024 * 1024 bytes)
    if (file.size > 10 * 1024 * 1024) {
      this.toaster.error(this.translate.instant('ERROR_MESSAGES.FILE_SIZE_MUST_NOT_EXCEED_10MB'));
      return;
    }

    // Check image dimensions
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      URL.revokeObjectURL(img.src);

      if (img.width < (options.minWidth || 512) || img.height < (options.minHeight || 512)) {
        this.toaster.error(
          this.translate.instant('ERROR_MESSAGES.IMAGE_DIMENSIONS_MUST_BE_AT_LEAST_512X512_PIXELS')
        );
        return;
      }

      // If all validations pass, proceed with file reading
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (options.previewElement && options.previewElementId) {
          options.previewElement.nativeElement.querySelector(options.previewElementId).src = reader.result;
        }

        options.form.patchValue({
          [options.formControlName]: reader.result,
        });
        options.form.controls[options.formControlName].markAsDirty();
        options.form.controls[options.formControlName].markAsTouched();
      };
    };

    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      this.toaster.error(this.translate.instant('Invalid image file'));
    };
  }
}
