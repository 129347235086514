<app-header></app-header>
<div class="e-view-student-page" *ngIf="student">
  <section id="main-content">
    <!-- Header -->
    <div class="e-view-student-page__header">
      <header class="c-page-header">
        <h2 class="c-page-header__title">
          {{ 'STUDENT_PAGE.STUDENT_DETAILS' | translate }}
        </h2>
        <app-button icon="pencil" [routerLink]="['/admin/student/edit-student', student?.user_id]">
          {{ 'Edit' | translate }}
        </app-button>
      </header>
    </div>

    <div class="e-view-student-page__stats">
      <div class="stat-chip">
        <span class="label">{{ 'STUDENT_PAGE.CURRENT_BELT' | translate }}</span>
        <span class="value">{{ student?.belt?.name || 'N/A' }}</span>
      </div>
      <div class="stat-chip">
        <span class="label">{{ 'STUDENT_PAGE.BELT_PROMOTION' | translate }}</span>
        <span class="value"
          >{{ student.checkins_in_current_belt_count }} / {{ student?.belt?.minimum_attendance || 'N/A' }}</span
        >
      </div>
      <div class="stat-chip">
        <span class="label">{{ 'STUDENT_PAGE.EXPIRES' | translate }}</span>
        <span class="value">{{ student.expiry_date || 'N/A' }}</span>
      </div>
    </div>

    <!-- Content -->
    <div class="e-view-student-page__content-box">
      <div class="e-view-student-page__tabs" #tabsContainer>
        <app-chip-list (chipSelected)="handleTabChange($event)">
          <app-choice-chip
            value="details"
            [selected]="true"
            icon="person"
            [label]="'STUDENT_PAGE.DETAILS' | translate">
          </app-choice-chip>
          <app-choice-chip value="attendance" icon="time" [label]="'STUDENT_PAGE.ATTENDANCE' | translate">
          </app-choice-chip>
          <app-choice-chip value="belt-history" icon="ribbon" [label]="'STUDENT_PAGE.BELT_HISTORY' | translate">
          </app-choice-chip>
          <app-choice-chip
            [disabled]="true"
            value="payment"
            icon="card"
            [label]="'STUDENT_PAGE.PAYMENTS' | translate">
          </app-choice-chip>
        </app-chip-list>
      </div>
      <div class="e-view-student-page__content-box__content">
        <div class="tab-content" [ngSwitch]="activeTab">
          <!-- Student Details Tab -->
          <div *ngSwitchCase="'details'" class="e-view-student-page__details">
            <div class="e-view-student-page__profile">
              <img
                [src]="student.photo_url || 'assets/images/empty-profile.jpg'"
                class="e-view-student-page__avatar"
                alt="" />
              <div class="e-view-student-page__info">
                <h3>{{ student.name }}</h3>
                <p><i class="fas fa-phone-alt"></i> {{ student.phone_code }} {{ student.phone_number }}</p>
                <p><i class="fas fa-envelope"></i> {{ student.email }}</p>
                <p><i class="fas fa-birthday-cake"></i> {{ student.dob }}</p>
              </div>
            </div>

            <div class="e-view-student-page__details-grid">
              <div class="detail-item">
                <span class="label">{{ 'STUDENT_PAGE.COUNTRY' | translate }}</span>
                <span class="value">{{ student.country }}</span>
              </div>
              <div class="detail-item">
                <span class="label">{{ 'STUDENT_PAGE.STATE' | translate }}</span>
                <span class="value">{{ student.state }}</span>
              </div>
              <div class="detail-item">
                <span class="label">{{ 'STUDENT_PAGE.CITY' | translate }}</span>
                <span class="value">{{ student.city }}</span>
              </div>
              <div class="detail-item">
                <span class="label">{{ 'STUDENT_PAGE.ZIP' | translate }}</span>
                <span class="value">{{ student.zip_code }}</span>
              </div>
              <div class="detail-item">
                <span class="label">{{ 'STUDENT_PAGE.ADDRESS' | translate }}</span>
                <span class="value">{{ student.address }}</span>
              </div>
            </div>

            <!--         
              <div class="detail-item">
                <span class="label">{{ 'STUDENT_PAGE.MEMBERSHIP_EXPIRES' | translate }}</span>
                <span class="value" *ngIf="hasMembership && !pausedMembership">
                  {{ student.membership_expiry_date | date: 'yyyy-MM-dd' }}
                </span>
                <span class="value" *ngIf="hasMembership && pausedMembership"> {{ 'STUDENT_PAGE.MEMBERSHIP_PAUSED' | translate }} </span>
              </div>
            </div> -->

            <div class="e-view-student-page__actions" *ngIf="hasMembership">
              <app-button
                *ngIf="!pausedMembership"
                variant="secondary"
                (click)="confirmPauseMembership(student.id)">
                {{ 'STUDENT_PAGE.PAUSE_MEMBERSHIP' | translate }}
              </app-button>
              <app-button *ngIf="pausedMembership" variant="primary" (click)="confirmResumeMembership(student.id)">
                {{ 'STUDENT_PAGE.RESUME_MEMBERSHIP' | translate }}
              </app-button>
            </div>
          </div>

          <!-- Contract Tab -->
          <!--      <div *ngSwitchCase="'contract'" class="e-view-student-page__contract">
            <iframe
              *ngIf="pdffile.changingThisBreaksApplicationSecurity != null"
              [src]="pdffile"
              class="e-view-student-page__pdf-viewer">
            </iframe>
            <div
              *ngIf="pdffile.changingThisBreaksApplicationSecurity == null"
              class="e-view-student-page__no-data">
              {{ 'Data Not Found' | translate }}
            </div>
          </div> -->

          <!-- Attendance Tab -->
          <div *ngSwitchCase="'attendance'">
            <app-admin-attendance></app-admin-attendance>
          </div>

          <!-- Payment Tab -->
          <div *ngSwitchCase="'payment'" class="e-view-student-page__payment">
            <!-- ... existing payment table with updated styling ... -->
          </div>

          <!-- Belt History Tab -->
          <div *ngSwitchCase="'belt-history'" class="e-view-student-page__belt-history">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th>{{ 'STUDENT_PAGE.DATE' | translate }}</th>
                    <th>{{ 'STUDENT_PAGE.PREVIOUS_BELT' | translate }}</th>
                    <th>{{ 'STUDENT_PAGE.NEW_BELT' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="isLoadingBeltHistory">
                    <td colspan="3" class="text-center pb-3 pt-3">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">{{ 'Loading' | translate }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="!isLoadingBeltHistory && beltHistory.length === 0">
                    <td colspan="3" class="text-center">{{ 'No belt history found' | translate }}</td>
                  </tr>
                  <tr *ngFor="let history of beltHistory">
                    <td>{{ history.created_at | date: 'medium' }}</td>
                    <td>{{ history.old_belt?.name || 'N/A' }}</td>
                    <td>{{ history.new_belt?.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
