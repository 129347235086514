<div class="e-attendance-page">
  <div class="e-attendance-page__content">
    <!-- Filter Section -->
    <div class="e-attendance-page__filter-section">
      <div class="d-flex gap-2 gap-md-3">
        <div class="e-attendance-page__stat-chip">
          <h5>{{ 'ATTENDANCE_PAGE.THIS_MONTH' | translate }}</h5>
          <ng-container *ngIf="!isDashboardLoading; else loadingState">
            <p>
              {{ dashboardData?.checkins_this_month || '0' }}
              <span>{{ 'ATTENDANCE_PAGE.CHECK_INS' | translate }}</span>
            </p>
          </ng-container>
        </div>
        <div class="e-attendance-page__stat-chip">
          <h5>{{ 'ATTENDANCE_PAGE.THIS_YEAR' | translate }}</h5>
          <ng-container *ngIf="!isDashboardLoading; else loadingState">
            <p>
              {{ dashboardData?.checkins_this_year || '0' }}
              <span>{{ 'ATTENDANCE_PAGE.CHECK_INS' | translate }}</span>
            </p>
          </ng-container>
        </div>

        <div class="e-attendance-page__stat-chip">
          <h5>{{ 'ATTENDANCE_PAGE.LIFETIME' | translate }}</h5>
          <ng-container *ngIf="!isDashboardLoading; else loadingState">
            <p>
              {{ dashboardData?.checkins_total || '0' }} <span>{{ 'ATTENDANCE_PAGE.CHECK_INS' | translate }}</span>
            </p>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-template #loadingState>
      <div class="skeleton-loader"></div>
    </ng-template>

    <!-- Table Section -->
    <div class="e-attendance-page__table-container">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th>{{ 'ATTENDANCE_PAGE.DATE' | translate }}</th>
              <th>{{ 'ATTENDANCE_PAGE.PROGRAM' | translate }}</th>
              <th>{{ 'ATTENDANCE_PAGE.START_TIME' | translate }}</th>
              <th>{{ 'ATTENDANCE_PAGE.CHECK_IN_TIME' | translate }}</th>
              <th>{{ 'ATTENDANCE_PAGE.BELT' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="isLoading">
              <td colspan="5" class="text-center pb-3 pt-3">
                <div class="spinner-border" role="status">
                  <span class="sr-only">{{ 'ATTENDANCE_PAGE.LOADING' | translate }}</span>
                </div>
              </td>
            </tr>
            <ng-container *ngIf="errorMessage; else content">
              <tr>
                <td colspan="5" class="text-center pb-3 pt-3">
                  <ion-icon name="alert-circle-outline"></ion-icon>
                  {{ errorMessage }}
                </td>
              </tr>
            </ng-container>
            <ng-template #content>
              <ng-container *ngIf="!errorMessage && listattendance">
                <tr
                  *ngFor="
                    let checkin of listattendance
                      | paginate
                        : {
                            itemsPerPage: pageSize,
                            currentPage: currentPage,
                            totalItems: totalItems,
                            id: 'attendance'
                          }
                  ">
                  <td>{{ checkin.session.date | date: 'dd-MM-YYYY' }}</td>
                  <td>{{ checkin.session.class.program.name }}</td>
                  <td>{{ checkin.session.start_time }}</td>
                  <td>{{ checkin.created_at | date: 'hh:mm a' }}</td>
                  <td>{{ checkin.belt.name }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="!isLoading && (!listattendance || listattendance.length === 0)">
                <tr>
                  <td colspan="5" class="text-center">{{ 'ATTENDANCE_PAGE.NO_RECORDS' | translate }}</td>
                </tr>
              </ng-container>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      id="attendance"
      class="text-end mt-4"
      (pageChange)="pageChange($event)"
      [previousLabel]="'Previous' | translate"
      [nextLabel]="'Next' | translate">
    </pagination-controls>
  </div>
</div>
