import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Belt,
  EditableSchoolDetails,
  OnboardingStep,
  Program,
  SchoolDetails,
  Video,
  VideoCategory,
  PaginatedResponse,
  PromotableStudent,
  RecentStudent
} from './admin.interface';


interface DashboardStatistics {
  students_count: number;
  checkins_this_month_count: number;
}


@Injectable({ providedIn: 'root' })
export class AdminService {
  constructor(private http: HttpClient) {}

  patchSchool(body: EditableSchoolDetails) {
    return this.http.patch(`${environment.BACKEND_BASE_URL}admin/school`, body);
  }

  getSchoolDetails(): Observable<SchoolDetails> {
    return this.http.get<SchoolDetails>(`${environment.BACKEND_BASE_URL}admin/school`);
  }

  getBeltList(): Observable<Belt[]> {
    return this.http.get<Belt[]>(`${environment.BACKEND_BASE_URL}admin/belts`);
  }

  patchBelt(belt_id: string, body: Partial<Belt>): Observable<Belt> {
    return this.http.patch<Belt>(`${environment.BACKEND_BASE_URL}admin/belts/${belt_id}`, body);
  }

  postBelt(body: Partial<Belt>): Observable<Belt> {
    return this.http.post<Belt>(`${environment.BACKEND_BASE_URL}admin/belts`, body);
  }

  getPrograms(): Observable<Program[]> {
    return this.http.get<Program[]>(`${environment.BACKEND_BASE_URL}admin/programs`);
  }

  postProgram(body: Partial<Program>): Observable<Program> {
    return this.http.post<Program>(`${environment.BACKEND_BASE_URL}admin/programs`, body);
  }

  patchProgram(program_id: string, body: Partial<Program>): Observable<Program> {
    return this.http.patch<Program>(`${environment.BACKEND_BASE_URL}admin/programs/${program_id}`, body);
  }

  getVideoCategories(): Observable<VideoCategory[]> {
    return this.http.get<VideoCategory[]>(`${environment.BACKEND_BASE_URL}admin/video-categories`);
  }

  getVideoCategory(video_category_id: string): Observable<VideoCategory> {
    return this.http.get<VideoCategory>(
      `${environment.BACKEND_BASE_URL}admin/video-categories/${video_category_id}`
    );
  }

  getVideosByCategory(video_category_id: string): Observable<Video[]> {
    return this.http.get<Video[]>(
      `${environment.BACKEND_BASE_URL}admin/video-categories/${video_category_id}/videos`
    );
  }

  patchVideoCategory(video_category_id: string, body: Partial<VideoCategory>): Observable<VideoCategory> {
    return this.http.patch<VideoCategory>(
      `${environment.BACKEND_BASE_URL}admin/video-categories/${video_category_id}`,
      body
    );
  }

  postVideoCategory(body: Partial<VideoCategory>): Observable<VideoCategory> {
    return this.http.post<VideoCategory>(`${environment.BACKEND_BASE_URL}admin/video-categories`, body);
  }

  postVideo(video_category_id: string, body: Partial<Video>): Observable<Video> {
    return this.http.post<Video>(
      `${environment.BACKEND_BASE_URL}admin/video-categories/${video_category_id}/videos`,
      body
    );
  }

  patchVideo(video_category_id: string, video_id: string, body: Partial<Video>): Observable<Video> {
    return this.http.patch<Video>(
      `${environment.BACKEND_BASE_URL}admin/video-categories/${video_category_id}/videos/${video_id}`,
      body
    );
  }

  deleteVideo(video_category_id: string, video_id: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.BACKEND_BASE_URL}admin/video-categories/${video_category_id}/videos/${video_id}`
    );
  }

  getVideo(video_category_id: string, video_id: string): Observable<Video> {
    return this.http.get<Video>(
      `${environment.BACKEND_BASE_URL}admin/video-categories/${video_category_id}/videos/${video_id}`
    );
  }
  getOnboardingSteps(): Observable<OnboardingStep[]> {
    return this.http.get<OnboardingStep[]>(`${environment.BACKEND_BASE_URL}admin/dashboard/steps`);
  }

  getDashboardStatistics(): Observable<DashboardStatistics> {
    return this.http.get<DashboardStatistics>(`${environment.BACKEND_BASE_URL}admin/dashboard/statistics`);
  }

  getPromotableStudents(page: number = 1): Observable<PaginatedResponse<PromotableStudent>> {
    return this.http.get<PaginatedResponse<PromotableStudent>>(
      `${environment.BACKEND_BASE_URL}admin/dashboard/students/ready-for-belt-promotion`,
      { params: { page: page.toString() } }
    );
  }

  getRecentStudents(page: number = 1): Observable<PaginatedResponse<RecentStudent>> {
    return this.http.get<PaginatedResponse<RecentStudent>>(
      `${environment.BACKEND_BASE_URL}admin/dashboard/students/registered-last-30-days`,
      { params: { page: page.toString() } }
    );
  }
}
