import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Slick } from 'ngx-slickjs';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from 'src/app/shared/services/api.services';
import Swal from 'sweetalert2';
import { AuthService } from './../../../shared/services/auth.service';
import { StripeService } from './../../../shared/services/stripe.service';

@Component({
  selector: 'app-admin-payment',
  templateUrl: './admin-payment.component.html',
  styleUrls: ['./admin-payment.component.css'],
})
export class AdminPaymentComponent implements OnInit {
  @ViewChild('closeCardModal') closeCardModal: ElementRef | any;
  @ViewChild('paymentcard') messageElement: ElementRef | any;
  @ViewChild('cardElement') cardElement: ElementRef | any;
  @ViewChild('cardElement2') cardElement2: ElementRef | any;

  config: Slick.Config = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  packageList: any = [];
  planeName: any;
  paymentHandler: any = null;
  school: any = [];
  selectplan: any;
  userrole: any;
  paymentForm: FormGroup | any;
  addCardForm: FormGroup | any;
  listcard: any = [];
  userid: any;
  carddata: any;
  card_id: any;
  addcard: any;
  routerid: any;
  router_id: any;
  schoolid: any;
  existadress:any;
  status: any;

  card: any; // Stripe card instance
  cardErrors: any; // Stripe card error

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private AuthService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private stripeService: StripeService
  ) {
    this.routerid = this.route.params;
    this.router_id = this.routerid._value.id;
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.status = params.get('status');
    });

    this.userrole = this.AuthService.userrole;
    if (this.userrole != 'ADMIN') {
      this.Apiservice.getApi(endpoints.schoolList.path).subscribe((data: any) => {
        this.school = data.school.filter((s: any) => s.status == 1);
        let admin = this.school.filter((s: any) => s.admin != null);
        this.schoolid = admin.find((s: any) => s.admin.id == this.router_id);
      });
    }

    this.paymentForm = this.formBuilder.group({
      plan_id: ['', Validators.required],
      user_id: [null],
      card_id: ['', Validators.required],
    });
    this.addCardForm=this.formBuilder.group({
      /*card_no:['',Validators.required],
      ccExpiryMonth:['',Validators.required],
      ccExpiryYear:['',Validators.required],
      cvvNumber:['',Validators.required],*/
      holder_name:['',Validators.required],
      address: ['', Validators.required],
      countryCode: ['', Validators.required],
      stateCode: ['', Validators.required],
      city: ['', Validators.required],
      zip_code: ['', Validators.required],
      user_id:[''],
      stripeToken: [''],
    })

    if (this.router_id) {
      this.addCardForm.controls['user_id'].setValue(this.router_id);
      this.paymentForm.controls['user_id'].setValue(this.router_id);
    }

    this.getPlanList();
    this.listCard();
    if(this.router_id && this.AuthService.packageid!=''){
      this.selectplan=this.AuthService.packageid
      this.paymentForm.controls['plan_id'].setValue(this.AuthService.packageid)
    }
  }

  ngAfterViewInit(): void {
    // Initialize Stripe Elements after the view has been initialized
    const stripe = this.stripeService.getStripe();

    const elements = stripe.elements({
      locale: 'en',
      appearance: {
        theme: 'stripe',
      },
    });

    this.card = elements.create('card', {
      style: {
        base: {
          iconColor: '#666EE8',
          color: '#31325F',
          fontWeight: '300',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSize: '18px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      },
      hidePostalCode: true // Hide the ZIP code field since we are getting it from billing details
    });

    this.card.mount(this.cardElement2.nativeElement);

    // Handle real-time validation errors
    this.card.addEventListener('change', (event: any) => {
      if (event.error) {
        this.cardErrors = event.error.message;
      } else {
        this.cardErrors = null;
      }
    });
  }

  get ff() {
    return this.paymentForm.controls;
  }

  get f() {
    return this.addCardForm.controls;
  }

  getPlanList() {
    this.Apiservice.getApi(endpoints.packageslist.path).subscribe(
      (data: any) => {
        this.packageList = data.data;
        if(this.AuthService.packageid!=''){
          let planprice=this.packageList.find((s:any)=>s.id==this.AuthService.packageid)
          this.planeName=planprice?.price
        }
      },
      (err: any) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  listCard() {
    if (this.userrole == 'ADMIN') {
      this.Apiservice.getApi(endpoints.listcard.path).subscribe(
        (data: any) => {
          this.listcard = data.response.card;
        },
        (err: any) => {
          this.toaster.error(err.error.message);
        }
      );
    } else if (this.router_id) {
      this.Apiservice.getApi(
        endpoints.listcard.path + '?user_id=' + this.router_id
      ).subscribe(
        (data: any) => {
          this.listcard = data.response.card;
        },
        (err: any) => {
          this.toaster.error(err.error.message);
        }
      );
    } else if (this.userid) {
      this.Apiservice.getApi(
        endpoints.listcard.path + '?user_id=' + this.userid
      ).subscribe(
        (data: any) => {
          this.listcard = data.response.card;
        },
        (err: any) => {
          this.toaster.error(err.error.message);
        }
      );
    }
  }
  selectUser(event: any) {
    let userid = this.school.find((s: any) => s.id == event);

    this.schoolid = userid;
    this.userid = userid.admin.id;
    this.paymentForm.controls['user_id'].setValue(userid.admin.id);
    this.addCardForm.controls['user_id'].setValue(userid.admin.id);
    this.Apiservice.getApi(
      endpoints.listcard.path + '?user_id=' + this.userid
    ).subscribe(
      (data: any) => {
        this.listcard = data.response.card;
      },
      (err: any) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  planList(plan: any) {
    this.planeName = plan.price;
    this.selectplan = plan.id;
    this.paymentForm.controls['plan_id'].setValue(plan.id);
  }

  openAddCardModal(event: any) {
    //Unmount payment card element
    if (this.cardElement2 && this.cardElement2.nativeElement) {
      this.card.unmount(this.cardElement2.nativeElement);
    }

    //Mount add card element
    if (this.cardElement && this.cardElement.nativeElement) {
      this.card.mount(this.cardElement.nativeElement);
    }
  }

  closeAddCardModal(event: any) {
    //Mount add card element
    if (this.cardElement && this.cardElement.nativeElement) {
      this.card.unmount(this.cardElement.nativeElement);
    }

    //Unmount payment card element
    if (this.cardElement2 && this.cardElement2.nativeElement) {
      this.card.mount(this.cardElement2.nativeElement);
    }
  }

  addCard(event: any) {
    this.submitted=true

    if (this.addCardForm.invalid) {
      return
    }

    // Create a Stripe token
    this.stripeService.getStripe().createToken(this.card, {
      name: this.addCardForm.value.holder_name,
      address_line1: this.addCardForm.value.address,
      address_city: this.addCardForm.value.city,
      address_state: this.addCardForm.value.stateCode,
      address_zip: this.addCardForm.value.zip_code,
      address_country: this.addCardForm.value.countryCode
    }).then((result: any) => {
      if (result.token) {
        console.log(result.token.id);
        // Set the stripeToken in the form
        this.addCardForm.controls['stripeToken'].setValue(result.token.id)

        // Send the token to backend
        this.Apiservice.postApi(endpoints.addcard.path, this.addCardForm.value).subscribe(
          (data: any) => {
            this.toaster.success(data.message)
            this.closeCardModal.nativeElement.click();
            this.messageElement.ccForm.reset()
            this.addCardForm.reset();
            this.addcard = 'addcard'
            this.listCard()
          }, (err: any) => {
            this.toaster.error(err.error.message)
          }
        );
      } else if (result.error) {
        // Inform the user if there was an error
        this.cardErrors = result.error.message;
      }
    });
  }

  onCardError(event: any) {
    if (event.error) {
      this.cardErrors = event.error.message
    } else {
      this.cardErrors = null
    }
  }

  Paymentcard(card: any) {
    this.card_id = card.id;
    this.paymentForm.controls['card_id'].setValue(card.id);
    // this.toaster.success(this.translate.instant('Card selected Sucessfully'))
  }
  initializePayment() {
    if (this.paymentForm.value.plan_id == '') {
      this.toaster.error(this.translate.instant('Select a package to payment'));
      return;
    } else if (this.paymentForm.value.card_id == '') {
      this.toaster.error(this.translate.instant('Select a card to payment'));
      return;
    }

    this.Apiservice.postApi(
      endpoints.addpayment.path,
      this.paymentForm.value
    ).subscribe(
      (data: any) => {
        this.toaster.success(this.translate.instant('Payment created successfully'));
        this.addcard = 'payment';
        if (this.userrole != 'ADMIN') {
          this.router.navigate([
            '/superadmin/school/view-school/' + this.schoolid.id,
          ]);
        } else {
          this.router.navigate(['/admin/school']);
        }
        this.paymentForm.reset();
      },
      (err: any) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  Countrychange(event: any) {
    this.addCardForm.controls['countryCode'].setValue(event.countryCode);
    this.addCardForm.controls['stateCode'].setValue(event.stateCode);
    this.addCardForm.controls['city'].setValue(event.city);
    this.addCardForm.controls['zip_code'].setValue(event.zip_code);
  }
  existingAddress(event:any){
    if(event.target.checked==true){
      if(this.userrole == 'ADMIN'){
        this.Apiservice.getApi(
          endpoints.editprofile.path
        ).subscribe((data) => {
          this.existadress=data
          this.addCardForm.controls['countryCode'].setValue(this.existadress.data.country_code);
          this.addCardForm.controls['stateCode'].setValue(this.existadress.data.state_code);
          this.addCardForm.controls['city'].setValue(this.existadress.data.city);
          this.addCardForm.controls['zip_code'].setValue(this.existadress.data.zip_code);
          this.addCardForm.controls['address'].setValue(this.existadress.data.address);
        });
      }else{

          this.Apiservice.getApi(
            endpoints.viewschool.path + '?school_id=' + this.schoolid.id
          ).subscribe((data) => {
            this.existadress=data
            this.addCardForm.controls['countryCode'].setValue(this.existadress.data.school.country_code);
            this.addCardForm.controls['stateCode'].setValue(this.existadress.data.school.state_code);
            this.addCardForm.controls['city'].setValue(this.existadress.data.school.city);
            this.addCardForm.controls['zip_code'].setValue(this.existadress.data.school.zip_code);
            this.addCardForm.controls['address'].setValue(this.existadress.data.school.address);
          });
        }



    }
  }

  confirmRemoveCard(cardId: string) {
    //If only one card left, they will need to add another card
    if (this.listcard.length === 1) {
      Swal.fire({
        title: this.translate.instant('Remove Card'),
        text: this.translate.instant('Please add another card before removing this one.'),
        icon: 'warning',
        confirmButtonText: 'OK'
      });

      return;
    }

    Swal.fire({
      title: this.translate.instant('Remove Card'),
      text: this.translate.instant('Are you sure you want to remove this card?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.removeCard(cardId);
      }
    });
  }

  removeCard(cardId: string) {
    console.log(cardId)

    if (cardId) {
      this.Apiservice.postApi(endpoints.deleteCard.path, {card: cardId}).subscribe(
        (data: any) => {
          //console.log(data);

          this.toaster.success(this.translate.instant(data.message));

          //Hide the card without page refresh
          const card = this.listcard.find((c: any) => c.id === cardId);
          if (card) {
            card.hidden = true;
          }
        },
        (err: any) => {
          this.toaster.error(this.translate.instant(err.error.message));
        }
      );
    }
  }
}
