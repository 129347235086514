import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Injectable } from '@angular/core';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminEditVideoCategoryResolver implements Resolve<any> {
  constructor(
    private AuthService: AuthService,
    private Apiservice: ApiService,
    private adminService: AdminService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise((resolve, reject) => {
      let videoId = route.paramMap.get('id');
      if (videoId) {
        this.adminService.getVideoCategory(videoId).subscribe((data: any) => {
          resolve(data);
        });
      } else {
        reject('Video ID is required');
      }
    });
  }
}
