<div id="left-sidebar" class="sidebar light_active">
  <button type="button" (click)="cToggoleMenu()" class="btn-toggle-offcanvas btn btn-sm float-right p-3">
    <i class="fas fa-times"></i>
  </button>
  <div class="navbar-brand">
    <a><img src="../../../../assets/img/tatami-logo.png" width="50" alt="" class="img-fluid logo" /> </a>
  </div>

  <nav id="left-sidebar-nav" class="sidebar-nav">
    <ul id="main-menu" class="metismenu animation-li-delay">
      <li>
        <a [routerLink]="['/admin/dashboard']" [routerLinkActive]="'active'"
          ><i class="fas fa-desktop"></i><span translate>SIDE_MENU.DASHBOARD</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/admin/school']" [routerLinkActive]="'active'"
          ><i class="fas fa-school"></i><span translate>SIDE_MENU.SCHOOL</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/admin/classes']" [routerLinkActive]="'active'"
          ><i class="fas fa-calendar-check"></i><span translate>SIDE_MENU.CLASS</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/admin/student']" [routerLinkActive]="'active'"
          ><i class="fas fa-user-graduate"></i><span translate>SIDE_MENU.STUDENT</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/admin/payment']" [routerLinkActive]="'active'"
          ><i class="fas fa-credit-card"></i><span translate>SIDE_MENU.PAYMENTS</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/admin/packages']" [routerLinkActive]="'active'"
          ><i class="fas fa-box"></i><span translate>SIDE_MENU.MEMBERSHIP_PACKAGES</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/admin/video-library']" [routerLinkActive]="'active'"
          ><i class="fas fa-video"></i><span translate>SIDE_MENU.VIDEO_LIBRARY</span></a
        >
      </li>
      <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <a
          class="settings-li"
          (click)="SalesCollapsed = !SalesCollapsed"
          [attr.aria-expanded]="!SalesCollapsed"
          aria-controls="collapseBasic"
          ><i class="fas fa-cog"></i><span translate>SIDE_MENU.SETTINGS</span
          ><ion-icon [name]="SalesCollapsed ? 'chevron-down-outline' : 'chevron-up-outline'"></ion-icon>
        </a>
        <ul class="list-dot" id="collapseBasic" [collapse]="SalesCollapsed" [isAnimated]="true">
          <!--   <li>
            <a [routerLink]="['/admin/staff']" [routerLinkActive]="'active'"
              ><i class="fas fa-user-circle"></i><span translate>SIDE_MENU.SETTINGS_SUBMENU.STAFF</span></a
            >
          </li> -->
          <li>
            <a [routerLink]="['/admin/programs']"
              ><i class="fas fa-home"></i><span translate>SIDE_MENU.SETTINGS_SUBMENU.PROGRAM</span></a
            >
          </li>
          <li>
            <a [routerLink]="['/admin/video-category']" [routerLinkActive]="'active'"
              ><i class="fas fa-video"></i><span translate>SIDE_MENU.SETTINGS_SUBMENU.VIDEO_CATEGORY</span></a
            >
          </li>
          <li>
            <a [routerLink]="['/admin/belt']" [routerLinkActive]="'active'"
              ><i class="fas fa-fill"></i><span translate>SIDE_MENU.SETTINGS_SUBMENU.BELT</span></a
            >
          </li>
        </ul>
      </li>

      <!--    <li>
        <a [routerLink]="['/admin/coach']" [routerLinkActive]="'active'"
          ><i class="fas fa-user"></i><span translate>SIDE_MENU.COACH</span></a
        >
      </li> -->
      <!--   <li>
        <a [routerLink]="['/admin/messages']" [routerLinkActive]="'active'"
          ><i class="fas fa-envelope"></i><span translate>SIDE_MENU.MESSAGES</span></a
        >
      </li> -->
      <!--    <li>
        <a [routerLink]="['/admin/notification']" [routerLinkActive]="'active'"
          ><i class="fas fa-bell"></i><span translate>SIDE_MENU.NOTIFICATION</span></a
        >
      </li> -->
    </ul>
  </nav>
  <ul class="action-list" *ngIf="isMobile">
    <li>
      <a href="javascript:void(0)" data-toggle="dropdown" aria-expanded="false"
        ><i class="fas fa-globe"></i><span translate></span
      ></a>
      <div class="dropdown-menu language-dropdown-menu mt-3" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item language-dropdown-items" (click)="useLanguage('en')">English</a>
        <a class="dropdown-item language-dropdown-items" (click)="useLanguage('es')">Spanish</a>
        <a class="dropdown-item language-dropdown-items" (click)="useLanguage('pt-br')">Portuguese</a>
      </div>
    </li>
    <li>
      <a (click)="logOut()" href="javascript:void(0)"><i class="fa fa-power-off"></i><span translate></span></a>
    </li>
  </ul>

  <div class="user-account profilepointer" [routerLink]="['/admin/profile']">
    <div class="user_div">
      <img src="assets/images/empty-profile.jpg" class="user-photo" alt="User Profile Picture" />
    </div>
    <div class="dropdown">
      <span translate>SIDE_MENU.PROFILE</span>
      <a href="javascript:void(0);" class="user-name"><strong translate>SIDE_MENU.ADMIN</strong></a>
    </div>
  </div>
</div>
