<main class="e-add-video-category-page">
  <div id="main-content">
    <div class="e-add-video-category-page__header">
      <div class="row justify-content-center">
        <div class="e-add-video-category-page__content-box col-12 col-md-10 col-lg-8">
          <div class="e-add-video-category-page__content-box__content">
            <div class="col-12" style="margin: auto">
              <form [formGroup]="categoryForm" (ngSubmit)="onSubmit()">
                <div class="c-page-header__divisor">
                  <div>
                    <div class="c-page-header__divisor__title">
                      <span>{{
                        categoryId
                          ? ('VIDEO_CATEGORY.EDIT_TITLE' | translate)
                          : ('VIDEO_CATEGORY.ADD_TITLE' | translate)
                      }}</span>
                    </div>
                    <div class="c-page-header__divisor__subtitle">
                      <span>{{
                        categoryId
                          ? ('VIDEO_CATEGORY.EDIT_SUBTITLE' | translate)
                          : ('VIDEO_CATEGORY.ADD_SUBTITLE' | translate)
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <app-field-input
                      formControlName="name"
                      type="text"
                      maxlength="20"
                      class="mt-3"
                      [isRequired]="true"
                      [clearButton]="true"
                      [label]="'VIDEO_CATEGORY.NAME' | translate"
                      [placeholder]="'VIDEO_CATEGORY.NAME' | translate">
                    </app-field-input>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <app-field-number
                      formControlName="order"
                      maxlength="3"
                      [isRequired]="true"
                      [clearButton]="true"
                      [label]="'VIDEO_CATEGORY.ORDER' | translate"
                      [placeholder]="'VIDEO_CATEGORY.ORDER' | translate">
                    </app-field-number>
                  </div>
                </div>

                <div class="d-flex justify-content-end mt-5">
                  <app-button
                    variant="secondary"
                    class="mr-3"
                    [isSubmit]="true"
                    [disabledStyleOnly]="true"
                    [routerLink]="['/admin/video-category']"
                    >{{ 'ADMIN_ACTIONS.BACK' | translate }}</app-button
                  >
                  <app-button
                    icon="checkmark"
                    [iconAfterLabel]="true"
                    variant="primary"
                    [isSubmit]="true"
                    [loading]="loading"
                    [disabled]="categoryForm.invalid"
                    [disabledStyleOnly]="true"
                    >{{
                      categoryId ? ('ADMIN_ACTIONS.SAVE' | translate) : ('ADMIN_ACTIONS.SUBMIT' | translate)
                    }}</app-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
