import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Video, VideoCategory } from '../admin.interface';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-video-library',
  templateUrl: './video-library.component.html',
  styleUrls: ['./video-library.component.scss'],
})
export class VideoLibraryComponent implements OnInit {
  videoCategories: VideoCategory[] = [];
  categoryVideos: { [key: string]: Video[] } = {};
  activeSettingsMenu: string | null = null;
  loadingStates: { [key: string]: boolean } = {};
  loadingCategories = false;
  collapsedCategories: { [key: string]: boolean } = {};
  toggleLoadingStates: { [key: string]: boolean } = {};

  constructor(
    private adminService: AdminService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadCategories();
  }

  private loadCategories() {
    this.loadingCategories = true;
    this.adminService.getVideoCategories().subscribe(
      (categories) => {
        this.loadingCategories = false;
        console.log('Setting categories', categories);
        this.videoCategories = categories.filter((cat) => cat.active);
        this.videoCategories.forEach((category) => {
          this.loadVideosForCategory(category.video_category_id);
        });
      },
      (error) => {
        this.toastr.error('Failed to load video categories');
        this.loadingCategories = false;
      }
    );
  }

  private loadVideosForCategory(categoryId: string) {
    this.loadingStates[categoryId] = true;
    this.adminService.getVideosByCategory(categoryId).subscribe(
      (videos) => {
        this.categoryVideos[categoryId] = videos;
        this.loadingStates[categoryId] = false;
      },
      (error) => {
        this.toastr.error(`Failed to load videos for category ${categoryId}`);
        this.loadingStates[categoryId] = false;
      }
    );
  }

  scrollLeft(categoryId: string) {
    const container = document.getElementById(`container-${categoryId}`);
    if (container) {
      container.scrollLeft -= 300;
    }
  }

  scrollRight(categoryId: string) {
    const container = document.getElementById(`container-${categoryId}`);
    if (container) {
      container.scrollLeft += 300;
    }
  }

  getVideoThumbnail(url: string): string {
    // Extract thumbnail from YouTube/Vimeo URL
    // This is a simple implementation - you might want to enhance it
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const videoId = url.split('v=')[1] || url.split('/').pop();
      return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    }
    // Add more video platform handlers as needed
    return 'assets/images/video-placeholder.jpg';
  }

  openVideo(video: Video) {
    if (video?.url) {
      window.open(video.url, '_blank');
    }
  }

  openAddVideoModal() {
    this.router.navigate(['/admin/video-library/add-video-into-category']);
  }

  editVideo(video_category_id: string, video_id: string) {
    this.router.navigate(['/admin/video-library/add-video-into-category/' + video_category_id + '/' + video_id]);
  }

  async deleteVideo(video: Video) {
    const result = await Swal.fire({
      title: this.translate.instant('VIDEO_LIBRARY.DELETE_TITLE'),
      text: this.translate.instant('VIDEO_LIBRARY.DELETE_TEXT'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      customClass: 'c-swal-modal',
      confirmButtonColor: '#2F69B0',
      cancelButtonColor: '#F1F8FF',
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      this.adminService.deleteVideo(video.video_category_id, video.video_id).subscribe(
        (response) => {
          this.toastr.success(this.translate.instant('VIDEO_LIBRARY.DELETE_SUCCESS'));
          this.loadCategories();
        },
        (error) => {
          this.toastr.error(this.translate.instant('VIDEO_LIBRARY.DELETE_ERROR'));
        }
      );
    }
  }

  toggleVideoStatus(video: Video) {
    this.toggleLoadingStates[video.video_id] = true;

    this.adminService.patchVideo(video.video_category_id, video.video_id, { active: !video.active }).subscribe(
      (response) => {
        this.toastr.success(this.translate.instant('VIDEO_LIBRARY.TOGGLE_STATUS_SUCCESS'));
        this.loadCategories();
        this.toggleLoadingStates[video.video_id] = false;
      },
      (error) => {
        this.toastr.error(this.translate.instant('VIDEO_LIBRARY.TOGGLE_STATUS_ERROR'));
        this.toggleLoadingStates[video.video_id] = false;
      }
    );
  }

  toggleSettingsMenu(videoId: string) {
    this.activeSettingsMenu = this.activeSettingsMenu === videoId ? null : videoId;
  }

  toggleCategory(categoryId: string) {
    this.collapsedCategories[categoryId] = !this.collapsedCategories[categoryId];
  }

  isCategoryCollapsed(categoryId: string): boolean {
    return this.collapsedCategories[categoryId] || false;
  }
}
