import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Injectable } from '@angular/core';
import { StudentService } from 'src/app/modules/admin/student/state/student.service';
import { ApiService } from 'src/app/shared/services/api.services';

@Injectable({
  providedIn: 'root',
})
export class AdminViewStudentResolver implements Resolve<any> {
  constructor(
    private Apiservice: ApiService,
    private studentService: StudentService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise((resolve, reject) => {
      let userId = route.paramMap.get('id');

      if (userId) {
        this.studentService.getStudent(userId).subscribe((data) => {
          resolve(data);
        });
      } else {
        reject(new Error('User ID is required'));
      }
    });
  }
}
