<main class="e-add-video-page">
  <div id="main-content">
    <div class="e-add-video-page__header">
      <div class="row justify-content-center">
        <div class="e-add-video-page__content-box col-12 col-md-10 col-lg-8">
          <div class="e-add-video-page__content-box__content">
            <div class="col-12" style="margin: auto">
              <form [formGroup]="videoForm" (ngSubmit)="onSubmit()">
                <div class="c-page-header__divisor">
                  <div>
                    <div class="c-page-header__divisor__title">
                      <span>{{
                        videoId
                          ? ('VIDEO_LIBRARY.EDIT_TITLE' | translate)
                          : ('VIDEO_LIBRARY.ADD_TITLE' | translate)
                      }}</span>
                    </div>
                    <div class="c-page-header__divisor__subtitle">
                      <span>{{
                        videoId
                          ? ('VIDEO_LIBRARY.EDIT_SUBTITLE' | translate)
                          : ('VIDEO_LIBRARY.ADD_SUBTITLE' | translate)
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <ng-container *ngIf="!categoriesLoading; else loadingCategories">
                      <app-field-select
                        formControlName="video_category_id"
                        [options]="categories"
                        [isRequired]="true"
                        [label]="'VIDEO_LIBRARY.CATEGORY' | translate"
                        [placeholder]="'VIDEO_LIBRARY.SELECT_CATEGORY' | translate"
                        optionValue="video_category_id">
                      </app-field-select>
                    </ng-container>
                    <ng-template #loadingCategories>
                      <div class="c-field">
                        <label class="c-field__label">{{ 'VIDEO_LIBRARY.CATEGORY' | translate }} *</label>
                        <div class="placeholder-loading"></div>
                      </div>
                    </ng-template>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <app-field-input
                      formControlName="title"
                      type="text"
                      [isRequired]="true"
                      [clearButton]="true"
                      [label]="'VIDEO_LIBRARY.TITLE' | translate"
                      [placeholder]="'VIDEO_LIBRARY.TITLE_PLACEHOLDER' | translate">
                    </app-field-input>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <app-field-input
                      formControlName="url"
                      type="url"
                      [isRequired]="true"
                      [clearButton]="true"
                      [label]="'VIDEO_LIBRARY.URL' | translate"
                      [placeholder]="'VIDEO_LIBRARY.URL_PLACEHOLDER' | translate">
                    </app-field-input>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <app-field-number
                      formControlName="order"
                      maxlength="3"
                      [clearButton]="true"
                      [isRequired]="true"
                      [label]="'VIDEO_LIBRARY.ORDER' | translate"
                      [placeholder]="'VIDEO_LIBRARY.ORDER_PLACEHOLDER' | translate">
                    </app-field-number>
                  </div>
                </div>

                <div class="d-flex justify-content-end mt-5">
                  <app-button
                    variant="secondary"
                    class="mr-3"
                    [isSubmit]="true"
                    [disabledStyleOnly]="true"
                    [routerLink]="['/admin/video-library']"
                    >{{ 'ADMIN_ACTIONS.BACK' | translate }}</app-button
                  >
                  <app-button
                    icon="checkmark"
                    [iconAfterLabel]="true"
                    variant="primary"
                    [isSubmit]="true"
                    [loading]="loading"
                    [disabled]="videoForm.invalid"
                    [disabledStyleOnly]="true"
                    >{{
                      videoId ? ('ADMIN_ACTIONS.SAVE' | translate) : ('ADMIN_ACTIONS.SUBMIT' | translate)
                    }}</app-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
