<div class="block-header">
  <div class="row clearfix registernone">
    <div class="col-xl-12 col-md-12 col-sm-12">
      <h2 translate>Add Payment</h2>
    </div>
    <div class="col-md-12">
      <div class="float-right">
        <a (click)="openAddCardModal($event)" class="btn-sm auth-button pay-button-bg" data-toggle="modal" data-target="#cardModal" translate>
          <i class="fas fa-credit-card mr-1 auth-button-top pay-button-bg" ></i>Add Card</a>
            <a class="btn-sm auth-button ml-2 auth-button-top pay-button-bg" [routerLink]="['/admin/payment']" translate *ngIf="userrole=='ADMIN'"><i
              class="fas fa-bars mr-1"></i>Payment</a>
              <a class="btn-sm auth-button ml-2 auth-button-top pay-button-bg" [routerLink]="['/student/payment']" translate *ngIf="userrole=='STUDENT'"><i
                class="fas fa-bars mr-1"></i>Payment</a>
      </div>
    </div>
  </div>

  <div class="row clearfix mt-3" *ngIf="status === 'expired'">
    <div class="col-12">
    <div class="alert alert-warning" role="alert" translate>
      Your membership is expired. Please renew your membership.
      </div>
    </div>
  </div>

  <div class="row clearfix mt-4" >
    <div class="col-lg-8 col-md-8 col-sm-8 p-5 auth-form" style="margin: auto;">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 " *ngIf="userrole=='ADMIN' && !router_id">
        <h6 class="head-colors" translate>Select Student</h6>
        <ng-select placeholder="{{'Select Student' | translate}}" (change)="selectUser($event)">
            <ng-option *ngFor="let status of student" [value]="status.id">{{status.first_name}} {{status.middle_name}} {{status.last_name}}
            </ng-option>
        </ng-select>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4 registerpackagenone">
        <h6 class="head-colors" translate>Packages</h6>
        <div class="" id="ngxdisable-btn">
          <div slickContainer #slickController="slick" [slickConfig]="config">
            <ng-container *ngIf="slickController.initialize">
              <div slickItem class="ngxslider-width" *ngFor="let plan of packageList">
                <div class="ngxmaincontent" [ngClass]="{activemenu:plan.id==selectplan}" (click)="planList(plan)">
                  <div class="ngxname-text">
                    <p>{{plan.plan_name}}</p>
                    <p class="datecolor">{{plan.valid_date}}</p>
                  </div>
                  <div class="ngxprice-text" style="text-transform: uppercase;">
                    <p class="text-center">{{school.default_currency}} {{plan.price}}</p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

    <div class="registerpaymentnone">
      <div class="row mt-4">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="listcard.length>0">
          <h6 class="head-colors" translate>Existing Card</h6>
          <div class="" id="ngxcarddisable-btn">
            <div slickContainer #cardsidController="slick" [slickConfig]="config">
              <ng-container *ngIf="cardsidController.initialize">
                <div slickItem class="ngxslider-width" *ngFor="let card of listcard">
                  <div *ngIf="!card.hidden" class="ngxmaincontent" [ngClass]="{activemenu:card.id==card_id}" (click)="Paymentcard(card)">
                    <div class="ngxname-text">
                      <p>{{card.holder_name}}</p>
                      <p class="datecolor">{{card.card.last4}}</p>
                    </div>
                    <div class="ngxprice-text">
                      <p>{{card.card.exp_month}}/{{card.card.exp_year}}</p>
                    </div>
                  </div>
                    <!-- Add the "Remove Card" button here -->
                    <div *ngIf="!card.hidden" class="text-center">
                        <button class="btn btn-danger btn-sm" (click)="confirmRemoveCard(card.id); $event.stopPropagation()" translate>Remove Card</button>
                    </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4" *ngIf="listcard.length==0 || this.paymentForm.value.card_id=='' && listcard.length==0 ">
        <div class="custom-control custom-checkbox" *ngIf="addcard!='addcard' ">
            <input type="checkbox" class="custom-control-input" id="customCheck1"  (change)="existingAddress($event)">
            <label class="custom-control-label head-colors" for="customCheck1" translate>To Use existing address</label>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4" *ngIf="listcard.length==0 || this.paymentForm.value.card_id=='' && listcard.length==0 ">
          <div *ngIf="addcard!='addcard'">
            <h6 class="head-colors" translate>Billing Address</h6>

            <form [formGroup]="addCardForm" (ngSubmit)="addCard($event)">
              <app-country-state (CountryChanged)="Countrychange($event)" [countryForm]="addCardForm"></app-country-state>
              <div class="form-group">
                <label class="mt-2" translate>Address<span
                        class="text-danger">*</span></label>
                <textarea placeholder="{{'Address' | translate}}" type="text"
                    class="form-control" formControlName="address"
                    maxlength="250"
                    [ngClass]="{'is-invalid': submitted && f.address.errors}"></textarea>
                <div *ngIf="submitted && f.address.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.address.errors.required" translate>
                        Address is required
                    </div>
                </div>
              </div>

              <!-- Card Details Section -->
              <div *ngIf="listcard.length==0 || (this.paymentForm.value.card_id=='' && listcard.length==0)">
                <h6 class="bill-text card-details-title" translate>Card Details</h6>
                <div class="form-group">
                  <input
                    id="holder_name"
                    type="text"
                    class="form-control holder-name-input"
                    formControlName="holder_name"
                    placeholder="Card Holder Name"
                    required
                  />
                  <div *ngIf="submitted && f.holder_name.errors" class="invalid-feedback">
                    <div *ngIf="f.holder_name.errors.required" translate>
                      Card holder name is required
                    </div>
                  </div>
                </div>

                <!-- Stripe Card Element -->
                <div class="card-element" #cardElement2></div>
                <div class="card-errors" role="alert" *ngIf="cardErrors">
                  {{ cardErrors }}
                </div>

                <!-- Submit Button -->
                <div class="d-flex justify-content-center">
                  <button type="submit" class="btn float-right auth-button mt-4">
                    Add Card
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="listcard.length==0 || this.paymentForm.value.card_id=='' && listcard.length==0">
          <!-- <ng-payment-card (formSaved)="addCard($event)" *ngIf="addcard!='addcard'"></ng-payment-card> -->
        </div>
        <button (click)="initializePayment()" class="btn float-right auth-button mt-4"
          >
            <p class="my-0"><span translate>Payment of </span> <span class="text-uppercase">{{school.default_currency}}</span> {{ planeName || 0}}</p>
        </button>
      </div>
  </div>
  </div>
</div>

<div class="modal fade" id="cardModal" tabindex="-1" role="dialog" aria-labelledby="cardModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h5 class="modal-title head-color" id="cardModalLabel">Add Card</h5>
        <button (click)="closeAddCardModal($event)" type="button" class="close" #closeCardModal data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- Modal Body -->
      <div class="modal-body">
        <form [formGroup]="addCardForm" (ngSubmit)="addCard($event)">
          <!-- Billing Address Section -->
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
            <h6 class="bill-text" translate>Billing Address</h6>
            <app-country-state (CountryChanged)="Countrychange($event)" [countryForm]="addCardForm"></app-country-state>
            <div class="form-group">
              <label class="mt-2" translate>Address<span class="text-danger">*</span></label>
              <textarea
                placeholder="{{ 'Address' | translate }}"
                class="form-control"
                formControlName="address"
                maxlength="250"
                [ngClass]="{'is-invalid': submitted && f.address.errors}"
              ></textarea>
              <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                <div *ngIf="f.address.errors.required" translate>
                  Address is required
                </div>
              </div>
            </div>
          </div>

          <!-- Card Details Section -->
          <h6 class="bill-text card-details-title" translate>Card Details</h6>
          <div class="form-group">
            <input
              id="holder_name"
              type="text"
              class="form-control holder-name-input"
              formControlName="holder_name"
              placeholder="Card Holder Name"
              required
            />
            <div *ngIf="submitted && f.holder_name.errors" class="invalid-feedback">
              <div *ngIf="f.holder_name.errors.required" translate>
                Card holder name is required
              </div>
            </div>
          </div>

          <!-- Stripe Card Element -->
          <div class="card-element" #cardElement></div>
          <div class="card-errors" role="alert" *ngIf="cardErrors">
            {{ cardErrors }}
          </div>

          <!-- Submit Button -->
          <div class="d-flex justify-content-center">
            <button type="submit" class="btn float-right auth-button mt-4">
              Add Card
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

