import { StudentData } from './student/state/student.interface';

export interface SchoolDetails extends EditableSchoolDetails {
  school_id: string;
  logo_url: string | null;
}

export interface EditableSchoolDetails {
  name: string;
  responsible: string;
  email: string;
  phone_code: string;
  phone_number: string;
  address: string;
  city: string;
  state: string | null;
  state_code: string;
  country: string | null;
  country_code: string;
  zip_code: string;
  timezone: string;
}

export interface Belt {
  belt_id: string;
  name: string;
  minimum_attendance: number;
  order: number;
  active: boolean;
  users_count: number;
}

export interface Program {
  program_id: string;
  name: string;
  classes_count: number;
  color: string;
  colors: Colors;
  active: boolean;
}

export enum ProgramColor {
  RED = 'red',
  GREEN = 'green',
  BLUE = 'blue',
  YELLOW = 'yellow',
  PURPLE = 'purple',
}

interface Colors {
  bg: string;
  font: string;
}

export interface VideoCategory {
  video_category_id: string;
  name: string;
  order: number;
  active: boolean;
  school_id: string;
  videos_count: number;
}

export interface Video {
  video_id: string;
  url: string;
  video_category_id: string;
  title: string;
  description: string;
  order: number;
  active: boolean;
}

export interface OnboardingStep {
  step: 'user_profile' | 'school_profile' | 'bank_account' | 'first_class' | 'first_student';
  completed: boolean;
}

export interface PromotableStudent extends StudentData {
  belt: Belt;
  checkins_in_current_belt_count: number;
}

export interface PaginatedResponse<T> {
  data: T[];
  meta: {
    current_page: number;
    per_page: number;
    total: number;
  };
}

export interface RecentStudent extends StudentData {
  user_id: string;
  name: string;
  created_at: string;
  programs_as_student: { name: string }[];
}
