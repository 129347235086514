import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Injectable } from '@angular/core';
import { AdminService } from 'src/app/modules/admin/admin.service';

@Injectable({
  providedIn: 'root',
})
export class AdminVideoResolver implements Resolve<any> {
  constructor(private adminService: AdminService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise((resolve, reject) => {
      let videoId = route.paramMap.get('id');
      let video_category_id = route.paramMap.get('video_category_id');
      if (videoId && video_category_id) {
        this.adminService.getVideo(video_category_id, videoId).subscribe((data: any) => {
          resolve(data);
        });
      } else {
        reject('Video ID is required');
      }
    });
  }
}
