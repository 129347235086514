import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

declare var Stripe: any; // Declare Stripe globally

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripe: any;

  constructor() {
    // Initialize Stripe with publishable key
    this.stripe = Stripe(environment.stripePublishableKey);
  }

  getStripe() {
    return this.stripe;
  }
}
