import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { VideoCategory } from '../../admin.interface';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss'],
})
export class AddVideoComponent implements OnInit {
  categoryForm!: FormGroup;
  loading = false;
  data: VideoCategory | undefined;
  categoryId: string | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private toaster: ToastrService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.categoryId = this.route.snapshot.params['id'];
    this.data = this.route.snapshot.data?.message;

    if (this.categoryId && this.data) {
      this.categoryForm = this.formBuilder.group({
        name: [this.data.name, [Validators.required, Validators.pattern("^[a-zA-Z -']+")]],
        order: [this.data.order, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      });
    } else {
      this.categoryForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.pattern("^[a-zA-Z -']+")]],
        order: [null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      });
    }
  }

  onSubmit() {
    if (this.categoryForm.invalid) {
      this.toaster.warning(this.translate.instant('FORM.SUBMIT_INVALID_FORM'));
      for (const key in this.categoryForm.controls) {
        this.categoryForm.controls[key].markAsDirty();
        this.categoryForm.controls[key].markAsTouched();
      }
      return;
    }

    if (this.loading) return;
    this.loading = true;

    // Only submit data that was changed by the user
    const requestData: Partial<VideoCategory> = {};
    for (const control in this.categoryForm.controls) {
      if (this.categoryId && !this.categoryForm.controls[control].touched) {
        continue;
      }
      requestData[control as keyof VideoCategory] = this.categoryForm.controls[control].value;
    }

    if (!this.categoryId) {
      this.adminService.postVideoCategory(requestData).subscribe({
        next: () => {
          this.loading = false;
          this.categoryForm.reset();
          this.toaster.success(this.translate.instant('VIDEO_CATEGORY.ADD_SUCCESS'));
          this.router.navigate(['/admin/video-category']);
        },
        error: (err) => {
          this.loading = false;
          this.toaster.error(err.error.message || err.error);
        },
      });
    } else {
      this.adminService.patchVideoCategory(this.categoryId, requestData).subscribe({
        next: () => {
          this.loading = false;
          this.categoryForm.reset();
          this.toaster.success(this.translate.instant('VIDEO_CATEGORY.UPDATE_SUCCESS'));
          this.router.navigate(['/admin/video-category']);
        },
        error: (err) => {
          this.loading = false;
          this.toaster.error(err.error.message || err.error);
        },
      });
    }
  }
}
